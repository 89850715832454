<template>

  <div class="row">

    <div id="top" class="col-lg-12">

      <div class="iq-card">


        <div class="iq-card-body p-0">
          <div class="iq-edit-list">
            <ul class="iq-edit-profile d-flex nav nav-pills">
              <li class="col-md-3 p-0">
                <a class="nav-link active" data-toggle="pill" href="#personal-information">
                  {{ $t("customer.profile_personal_info") }}
                </a>
              </li>
              <li class="col-md-3 p-0">
                <a class="nav-link" data-toggle="pill" href="#chang-pwd">
                  {{ $t("customer.profile_change_password") }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="iq-edit-list-data">
        <div class="tab-content">
          <div class="tab-pane fade active show" id="personal-information" role="tabpanel">
            <div class="iq-card">
              <div class="iq-card-header d-flex justify-content-between">
                <div class="iq-header-title">
                  <h4 class="card-title">{{ $t("customer.profile_personal_info") }}</h4>
                </div>
              </div>
              <div class="iq-card-body">
                <form>
                  <div class="form-group row align-items-center">
                    <div class="col-md-12">
                      <div v-if="hasProfilePhoto && !showUploader" class="profile-img-edit">
                        <img class="profile-pic" :src="$mediaBaseUrl + photo" alt="profile-pic">
                        <div class="p-image">
                          <i class="ri-pencil-line upload-button" @click="showUploader = true"></i>
                          <input class="file-upload" type="file" accept="image/*"/>
                        </div>
                      </div>
                      <div v-else-if="clientData">
                        <h4>{{ $t("edit_customer.profile_photo_upload") }}</h4>
                        <PhotoUploader :profilePhoto="true" @click="showUploader = false"/>
                      </div>
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="form-group col-sm-6">
                      <label v-if="clientData" for="fname">{{ $t("edit_customer.name") }}</label>
                      <label v-else-if="customerData" for="fname">{{ $t("edit_customer.user_name") }}</label>
                      <input type="text" class="form-control" id="fname" v-model="first_name" required>
                    </div>
                    <div v-show="this.clientData" class="form-group col-sm-6">
                      <label for="lname">{{ $t("edit_customer.lastname") }}</label>
                      <input type="text" class="form-control" id="lname" v-model="last_name" required>
                    </div>
                    <div v-show="this.clientData" class="form-group col-sm-6">
                      <label>{{ $t("edit_customer.gender") }}</label>
                      <select class="form-control" id="gender" v-model="gender">
                        <option v-for="option in genderOptions" v-bind:value="option.id" v-bind:key="option.id" required>
                          {{ option.name }}
                        </option>
                      </select>
                    </div>
                    <div v-show="this.clientData" class="form-group col-sm-6">
                      <label for="dob">{{ $t("edit_customer.birthdate") }}</label>
                      <input type="date" class="form-control" id="dob" v-model="birth_date" required>
                    </div>
                    <div v-show="this.clientData" class="form-group col-sm-6">
                      <label>{{ $t("edit_customer.nationality") }}</label>
                      <select class="form-control" id="nationality" v-model="nationality">
                        <option v-for="option in nationalityOptions" v-bind:value="option.id" v-bind:key="option.id" required>
                          {{ option.name }}
                        </option>
                      </select>
                    </div>
                    <div v-show="this.clientData" class="form-group col-sm-6">
                      <label for="address">{{ $t("edit_customer.address") }}</label>
                      <input type="text" class="form-control" id="address" v-model="location" required>
                      <ul style>
                        <li v-for="(result, i) in searchResults" :key="i">
                          {{ result }}
                        </li>
                      </ul>
                    </div>
                    <div class="form-group col-sm-6">
                      <label for="email">{{ $t("edit_customer.email") }}</label>
                      <input type="text" class="form-control" id="email" v-model="email" required>
                    </div>
                    <div v-show="this.clientData" class="form-group col-sm-6">
                      <label for="phone">{{ $t("edit_customer.phone_number") }}</label>
                      <input type="tel" class="form-control" id="phone"
                             v-model="phone_number" required>
                    </div>


                    <div v-show="this.clientData" class="form-group col-sm-6">
                      <label for="height">{{ $t("edit_customer.height") }}</label>
                      <input type="number" class="form-control" id="height" v-model="height" required>
                    </div>
                    <div v-show="this.clientData" class="form-group col-sm-6">
                      <label for="weight">{{ $t("edit_customer.weight") }}</label>
                      <input type="number" class="form-control" id="weight" v-model="weight" required>
                    </div>

                    <div v-show="this.clientData" class="form-group col-sm-6">
                      <label>{{ $t("edit_customer.services") }}</label>
                      <multiselect v-model="services" :options="servicesOptions"
                                   :placeholder="$t('edit_customer.choose_a_service')" label="name" track-by="name" :multiple="true"
                                   :taggable="true" @tag="addTag">
                      </multiselect>
                    </div>

                    <div v-show="this.clientData" class="form-group col-sm-6">
                      <label>{{ $t("edit_customer.orientation") }}</label>
                      <multiselect v-model="orientations" :options="orientationsOptions"
                                   :placeholder="$t('edit_customer.choose_a_orientation')" label="name" track-by="name"
                                   :multiple="true"
                                   :taggable="true" @tag="addTag">
                      </multiselect>
                    </div>

                    <div v-show="this.clientData" class="form-group col-sm-12">
                      <label>{{ $t("edit_customer.about_me") }}</label>
                      <textarea class="form-control" name="description" rows="10" v-model="description"
                                style="line-height: 22px; height: 100px">

                                             </textarea>
                    </div>
                  </div>
                  <flash-message class="flash-message"></flash-message>
                  <input type="button" class="btn-primar mr-2" @click="save" :value="$t('general.save')">
                </form>

              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="chang-pwd" role="tabpanel">
            <div class="iq-card">
              <div class="iq-card-header d-flex justify-content-between">
                <div class="iq-header-title">
                  <h4 class="card-title">{{ $t("edit_customer.change_password") }}</h4>
                </div>
              </div>
              <div class="iq-card-body">
                <form>
                  <div class="form-group">
                    <label for="cpass">{{ $t("edit_customer.current_password") }}</label>
                    <a href="javascripe:void();" class="float-right">{{ $t("edit_customer.forgotten_password") }}</a>
                    <input type="Password" class="form-control" id="cpass" value="">
                  </div>
                  <div class="form-group">
                    <label for="npass">{{ $t("edit_customer.new_password") }}</label>
                    <input type="Password" class="form-control" id="npass" value="">
                  </div>
                  <div class="form-group">
                    <label for="vpass">{{ $t("edit_customer.confirm_password") }}</label>
                    <input type="Password" class="form-control" id="vpass" value="">
                  </div>
                  <input type="button" class="btn-primar mr-2" :value="$t('edit_customer.confirm')">
                </form>
              </div>
            </div>

          </div>

        </div>

      </div>

    </div>

  </div>
</template>


<script>
import {socialvue} from '@/config/pluginInit'
import api from "@/api/api";
import Multiselect from 'vue-multiselect'
import PhotoUploader from "./Components/PhotoUploader";

export default {
  name: 'EditCustomerPage',
  components: {Multiselect, PhotoUploader},
  computed: {
    customerData() {
      return this.$store.getters["Setting/customerData"];
    },
    clientData() {
      return this.$store.getters["Setting/clientData"];
    },
    token() {
      return this.$store.getters["Setting/token"];
    },
    hasProfilePhoto: {
      get() {
        if (this.customerData) {
          return false;
        } else if (this.clientData) {
          return !!this.clientData.photos[0];
        } else {

          return false
        }
      }
    },

  },
  mounted() {

    socialvue.index()
    api.post("client/getGenericData", {}).then((response) => {

      this.genderOptions = response.data.genders

      this.servicesOptions = response.data.services
      this.orientationsOptions = response.data.orientations
      this.nationalityOptions = response.data.nationalities

      if (this.customerData) {
        this.first_name = this.customerData.first_name;
        this.last_name = this.customerData.last_name;
        this.email = this.customerData.email;
      }
      if (this.clientData) {
        if (this.clientData.photos[0]) {
          this.photo = this.clientData.photos[0].url;
        } else {
          this.photo = null
        }
        if (this.clientData.first_name) {
          this.first_name = this.clientData.first_name
        } else {
          this.first_name = null
        }
        if (this.clientData.last_name) {
          this.last_name = this.clientData.last_name
        } else {
          this.last_name = null
        }
        if (this.clientData.email) {
          this.email = this.clientData.email

        } else {
          this.email = null
        }
        if (this.clientData.phone_number) {
          this.phone_number = this.clientData.phone_number
        } else {
          this.phone_number = null
        }
        if (this.clientData.nationality) {
          this.nationality = this.clientData.nationality
        } else {
          this.nationality = null
        }
        if (this.clientData.height) {
          this.height = this.clientData.height
        } else {
          this.height = null
        }
        if (this.clientData.weight) {
          this.weight = this.clientData.weight
        } else {
          this.weight = null
        }
        if (this.clientData.birth_date) {
          this.birth_date = this.clientData.birth_date
        } else {
          this.birth_date = null
        }
        if (this.clientData.description) {
          this.description = this.clientData.description
        } else {
          this.description = null
        }
        if (this.clientData.location) {
          this.location = this.clientData.location
        } else {
          this.location = null
        }
        if (this.clientData.gender) {
          this.gender = this.clientData.gender
        } else {
          this.gender = null
        }

        if (this.clientData.services) {
          this.services = this.setDefaultValues(this.clientData.services, this.servicesOptions)
        } else {
          this.services = null
        }

        if (this.clientData.orientations) {
          this.orientations = this.setDefaultValues(this.clientData.orientations, this.orientationsOptions)
        } else {
          this.orientations = null
        }

      }
    })
    this.getLocation()
  },
  data() {
    return {
      photo: null,
      submitted: false,
      first_name: null,
      last_name: null,
      phone_number: null,
      nationality: null,
      height: null,
      weight: null,
      birth_date: null,
      description: null,
      location: null,
      gender: null,
      services: null,
      orientations: null,
      latitude: null,
      longitude: null,
      email: null,
      nationalityOptions: [],
      genderOptions: [],
      servicesOptions: [],
      orientationsOptions: [],
      searchResults: [],
      service: null,
      showUploader: false
    }
  },
  metaInfo() {
    return {
      script: [{
        src: `https://maps.googleapis.com/maps/api/js?key=AIzaSyDAIBuTBt8bPTxn4AIs77CsgqyvpSsb7C4&libraries=places`,
        async: true,
        defer: true,
        callback: () => this.MapsInit() // will declare it in methods
      }]
    }
  },
  created() {
    // reset login status
  }
  ,
  methods: {
    MapsInit() {
      this.service = new window.google.maps.places.AutocompleteService()
    },
    displaySuggestions(predictions, status) {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        this.searchResults = []
        return
      }
      this.searchResults = predictions.map(prediction => prediction.description)
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        id: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.orientationsOptions.push(tag)
      this.orientations.push(tag)
    },
    closeUploader(newPhoto) {
      this.photo = newPhoto;
      this.showUploader = false
    },
    showLocation(position) {
      let latitude = position.coords.latitude;
      let longitude = position.coords.longitude;
      this.latitude = latitude
      this.longitude = longitude
      this.location = [latitude, longitude]
    }
    ,
    errorHandler(err) {
      if (err.code == 1) {
        alert("Error: Access is denied!");
      } else if (err.code == 2) {
        alert("Error: Position is unavailable!");
      }
    }
    ,
    getLocation() {
      if (navigator.geolocation) {

        // timeout at 60000 milliseconds (60 seconds)
        let options = {timeout: 60000};
        navigator.geolocation.getCurrentPosition(this.showLocation, this.errorHandler, options);
      } else {
        alert("Sorry, browser does not support geolocation!");
      }
    }
    ,
    getIdsFromMultiselect(values) {
      let ids = [];

      for (let value in values) {

        ids.push(values[value].id)
      }

      return ids
    }
    ,
    setDefaultValues(values, options) {
      let resultObject = []
      for (let value in values) {

        resultObject.push({
          name: this.getOptionNameById(options, values[value]),
          id: values[value]
        });
      }

      return resultObject
    }
    ,
    getOptionNameById(options, id) {

      return options.find(x => x.id === id).name;
    }
    ,
    save() {
      if (this.clientData) {
        this.saveClient()
      } else if (this.customerData) {
        this.saveCustomer()
      }
    },
    saveCustomer() {

      api
          .post("customer/setup", {
            token: this.token,
            first_name: this.first_name,
            last_name: "",
            email: this.email
          })
          .then((response) => {
            if (response.data.status) {
              this.flash('Saved successfully', 'success');
              api.post("customer/getSelf", {
                token: this.token
              }).then((newResponse) => {
                this.$store.dispatch('Setting/setCustomerData', newResponse.data.customer)
              })
            }

          })
    }
    ,
    saveClient() {

      api
          .post("client/setup", {
            token: this.token,
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email,
            phone_number: this.phone_number,
            nationality: this.nationality,
            height: this.height,
            weight: this.weight,
            birth_date: this.birth_date,
            description: this.description,
            location: this.location,
            gender: this.gender,
            services: this.getIdsFromMultiselect(this.services),
            orientations: this.getIdsFromMultiselect(this.orientations)
          })
          .then((response) => {
            if (response.data.status) {
              this.flash('Saved successfully', 'success');
              api.post("client/getSelf", {
                token: this.token
              }).then((newResponse) => {
                this.$store.dispatch('Setting/setClientData', newResponse.data.client)
              })
            }

          })
    }
  },
  watch: {
    location(newValue) {
      if (newValue) {
        this.service.getPlacePredictions({
          input: this.location,
          types: ['(cities)']
        }, this.displaySuggestions)
      }
    }
  }
}
</script>
<style>
.multiselect__tag, .multiselect__option--highlight, .multiselect__option--highlight::after {
  background-color: #ef476f !important;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css">

</style>

<style scoped>

.nav-link.active {
  background-color: #ef476f !important;
}

input[type="button"] {
  background-color: #ef476f;
  color: white;
  width: 100%;
  height: 50px;
  margin-top: 20px;
  margin-bottom: 50px;
}

.flash-message {
  z-index: 2000;
  position: fixed;
  width: inherit;
  display: contents;

}

.upload-button {
  color: #ef476f;
}

.profile-pic {
  cursor: pointer;
}

option {
  color: #495057;
}
</style>
