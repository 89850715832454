<template>
  <div class="vue-tempalte">

    <uploader
        v-model="fileList"
        :autoUpload="false"
        @on-change="submit"
        name="upload"
        :limit="limit"
    ></uploader>
  </div>
</template>


<script>
import {socialvue} from '@/config/pluginInit'
import api from "@/api/api";
import Uploader from "vux-uploader-component";

export default {
  name: "UploadPhotosPage",
  props: {
    profilePhoto: Boolean
  },
  components: {Uploader},
  mounted() {
    socialvue.index()
    if (this.profilePhoto) {
      this.limit = 1;
    } else {
      this.limit = 5
    }
  },
  computed: {
    token() {
      return this.$store.getters["Setting/token"];
    }
  },
  data() {
    return {
      submitted: false,
      fileList: [],
      limit: null,
      image: null
    }
  },
  created() {
    // reset login status
  },
  methods: {
    submit() {
      this.getBase64(this.fileList[0].blob).then(
          data => {
            let base64 = data.substr(data.indexOf(',') + 1)
            api
                .post("client/uploadPhoto", {
                  token: this.token,
                  extension: "png",
                  image: base64,
                  is_profile: this.profilePhoto
                }).then((response) => {
              if (response.data.status) {
                this.flash('Saved successfully', 'success');
                this.updateProfileData()
                if (this.profilePhoto) {
                  this.$parent.closeUploader(response.data.photo.url)
                }

              }
            })
          }
      )
    },
    updateProfileData() {
      api.post("client/getSelf", {
        token: this.token
      }).then((newResponse) => {
        this.$store.dispatch('Setting/setClientData', newResponse.data.client)
      })
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }
  }
};
</script>


<style>
.vux-uploader .vux-uploader_bd .vux-uploader_files .vux-uploader_file {
  height: 120px !important;
}

.vux-uploader_title {
  display: none !important;
}

.vux-uploader_input {
  cursor: pointer;
}
</style>
